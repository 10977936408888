import styled from "@emotion/styled"
import React from "react"
import { fonts, colors, device } from "./layout/GlobalStyles"
import { Button } from "./layout/StyledComponents"

const MainContainer = styled.div`
  padding: 72px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${colors.primary2};
  color: ${colors.white};
  p {
    font-size: 22px;
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    .flex-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    padding: 128px 0px;
  }
`

const LeftColumn = styled.div`
  margin-bottom: 72px;
  h4 {
    margin: 0;
    color: ${colors.white};
    font-size: 50px;
    font-weight: 800;
    letter-spacing: -1.56px;
    line-height: 55px;
  }
  p {
    width: 222px;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  @media ${device.tablet} {
    margin-bottom: 0px;
  }
`

const RightColumn = styled.div`
  max-width: 340px;

  p {
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  h5 {
    margin: 0;
    font-family: ${fonts.secondary};
    font-size: 30px;
    font-weight: 800;
    letter-spacing: -0.94px;
    line-height: 40px;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 22px;
      margin: 15px 0px;
      font-family: ${fonts.primary};
    }
  }
  @media ${device.tablet} {
    max-width: 733px;
    margin-top: -30px;
    h5 {
      margin-top: 40px;
    }
    .number-container {
      justify-content: space-between;
      display: flex;
    }
  }
`

const BottomSection = styled.div`
  margin-top: 50px;
`

export const ProgramsSchedule = () => {
  return (
    <MainContainer>
      <div className="flex-container container">
        <LeftColumn>
          <h4>Program Schedule</h4>
        </LeftColumn>
        <RightColumn>
          <div>
            <h5>Orientation / Launch July 31 - August 1</h5>
            <p>
              MLOVE Fellows gather with previous year's Fellows to share their
              expectations and experiences.{" "}
            </p>
          </div>
          <div>
            <h5>1st Quarter / August-October / Communication</h5>
            <p>
              We start by first exploring how we communicate with ourselves and
              the outside world. Reading critically and creatively and the
              concept of storytelling are used to help us share our ideas better
              with others.
            </p>
          </div>
          <div>
            <h5>2nd Quarter / November-January / Entrepreneurship </h5>
            <p>
              Next, we explore the strategies needed to excel as an
              entrepreneur. We focus on legal and tax frameworks and compliance
              requirements for businesses and entrepreneurs. This module invites
              entrepreneurs to imagine how their products and services can be
              people-impacting and mission-driven.
            </p>
          </div>
          <div>
            <h5>3rd Quarter / February-April / Leadership </h5>
            <p>
              Building on the last two modules, we introduce global leadership
              concepts and explore hard and soft power in leadership, management
              techniques, and coaching.
            </p>
          </div>
          <div>
            <h5>4th Quarter / May-July / Fundraising </h5>
            <p>
              Now that participants have acquired essential leadership and
              entrepreneurial skills, we focus on two external keys to success,
              networks, and funding. In this module, young leaders will create
              their life plan strategy in these three areas:
            </p>
            <ul>
              <li>Resources to advance their careers</li>
              <li>Funding college education</li>
              <li>Funding entrepreneurial ideas </li>
            </ul>
          </div>
          <BottomSection>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/ujVoj17CRjGwffJp8"
            >
              <Button color="yellow">Apply today</Button>
            </a>
            <div className="bottom-container">
              <p style={{ fontWeight: "700" }}>Questions? Contact us.</p>
              <p>
                Mwangi M. Ndegwa <br />
                Program Director, MLOVE
                <br />
                mndegwa@mlifefoundation.org
              </p>
            </div>
          </BottomSection>
        </RightColumn>
      </div>
    </MainContainer>
  )
}
