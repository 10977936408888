import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"
import { Fancybox } from "../Fancy-Box"

const MainContainer = styled.div`
  padding: 72px 0px;
  background-color: #3e7ab1;
  color: ${colors.white};
  h4 {
    color: ${colors.white};
  }
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 128px 0px 128px;
    img {
      width: 476px;
    }
    .text-container {
      h4 {
        margin-bottom: 25px;
        font-size: 50px;
        font-weight: 800;
        letter-spacing: -1.56px;
        line-height: 55px;
        margin: 0;
      }
      width: 470px;
      margin-left: 90px;
    }
  }
`

export const FuturePanel = () => {
  return (
    <MainContainer>
      <PanelContainer>
        '{" "}
        <Fancybox options={{ infinite: false }}>
          <button
            style={{ border: "none", background: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/724244308"
          >
            {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/724244308"
        > */}
            <StaticImage
              src="../../images/programs/mlove/future-play.png"
              placeholder="none"
              quality={100}
              alt="man standing by pool"
            />
          </button>
        </Fancybox>
        {/* </a> */}
        <div className="text-container">
          <h4>The Future is Human Leadership Symposiums </h4>
          <p>
            These Leadership retreats are three-day events held in January,
            July, and October to provide facilitated opportunities for cohorts
            to forge lifelong relationships with peers.
          </p>
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
