import styled from "@emotion/styled"
import React, { useState } from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { Button } from "../layout/StyledComponents"
import { Fancybox } from "../Fancy-Box"
import { PanelContainer } from "./Panel-Container"
import { Tooltip } from "../Tooltip"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.primary1};
  p {
    font-size: 22px;
    letter-spacing: -0.69px;
    line-height: 36px;
  }
  .first-par {
    margin-top: 50px;
  }
  @media ${device.tablet} {
    .video-image {
      cursor: pointer;
      img {
        transition: 0.2s ease-in-out;
      }
    }
    .video-image:hover {
      img {
        transform: scale(0.99);
      }
    }
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 74px 0px 87px;
    button {
      margin-top: 30px;
    }
    > section:first-of-type {
      .main-image,
      .video-image {
        img {
          width: 476px;
        }
      }
    }
    > section:last-of-type {
      margin-top: 70px;
      .main-image,
      .video-image {
        img {
          width: 543px;
        }
      }
    }
    .first-section {
      width: 543px;
      margin-left: 90px;
    }
    .second-section {
      width: 456px;
      margin-bottom: 40px;
    }
  }
`

const tooltipText = ["Mwangi Mukami Leaders of Openness, Virtue, and Empathy."]

export const MLovePanels = ({
  setToolTipMobile,
  toolTipMobile,
  hovered,
  setHovered,
}) => {
  const [name, setName] = useState("")
  const hoverAction = (bool, name) => {
    setName(name)
    setHovered(bool)
  }
  return (
    <MainContainer>
      <PanelContainer>
        <StaticImage
          className="main-image"
          src="../../images/programs/mlove/group-picture.png"
          placeholder="none"
          quality={100}
          alt="student writing in classroom"
        />
        <div className="first-section">
          <p className="first-par">
            Instituted by Mwangi Mukami in 2017 as Summer Leaders’ Summit &
            Retreat,{" "}
            <span
              onClick={() => setToolTipMobile(active => !active)}
              onMouseOver={() => hoverAction(true, "MLOVE")}
              onMouseLeave={() => hoverAction(false, "")}
            >
              {name === "MLOVE" ? (
                <Tooltip
                  buttonText="View full glossary"
                  toolTipMobile={toolTipMobile}
                  setToolTipMobile={setToolTipMobile}
                  text={tooltipText[0]}
                  hovered={hovered}
                  setHovered={setHovered}
                >
                  MLOVE
                </Tooltip>
              ) : (
                <div className="special"> MLOVE</div>
              )}
            </span>{" "}
            is a premier multidisciplinary and multicultural fellowship for
            under-resourced high-impacting and high-potential NextGen leaders.
          </p>
          <p>
            This transformative 12-month experiential leadership program
            provides young leaders a vulnerable and safe space to be human,
            collaborate and partner with others, learn from world-class faculty,
            and become just leaders.
          </p>
        </div>
      </PanelContainer>
      <PanelContainer reverse>
        {/* <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://vimeo.com/716495444"
        > */}
        <Fancybox options={{ infinite: false }}>
          <button
            style={{ border: "none", background: "none" }}
            data-fancybox="gallery"
            data-src="https://vimeo.com/716495444"
            className="video-button"
            color="yellow"
          >
            <StaticImage
              className="video-image"
              style={{ marginTop: "30px" }}
              src="../../images/programs/mlove/teaching-class.png"
              placeholder="none"
              quality={100}
              alt="people learning in a classroom"
            />
          </button>
        </Fancybox>
        {/* </a> */}
        <div className="second-section">
          <p className="first-par">
            MLOVE Fellows receive a financial stipend, mentorship and coaching,
            and other tools they need to ideate, define, and execute a strategy
            that gives them an advantage in a complex and changing world.
          </p>
          {/* <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://vimeo.com/716495444"
          > */}
          <Fancybox options={{ infinite: false }}>
            <Button
              data-fancybox="gallery"
              data-src="https://vimeo.com/716495444"
              className="video-button"
              color="yellow"
            >
              Watch & learn more
            </Button>
          </Fancybox>
          {/* </a> */}
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
