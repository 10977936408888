import styled from "@emotion/styled"
import React from "react"
import { colors, device } from "../layout/GlobalStyles"
import { StaticImage } from "gatsby-plugin-image"
import { PanelContainer } from "./Panel-Container"

const MainContainer = styled.div`
  padding: 72px 0px;
  background: ${colors.secondary203};
  p {
    letter-spacing: -0.69px;
    line-height: 36px;
    font-size: 22px;
  }
  @media ${device.tablet} {
    img {
      width: 500px;
    }
  }
  @media ${device.laptop} {
    padding: 138px 0px 128px;
    img {
      width: 476px;
    }
    .text-container {
      h4 {
        margin-bottom: 25px;
        font-size: 50px;
        font-weight: 800;
        letter-spacing: -1.56px;
        line-height: 55px;
        margin: 0;
      }
      p:first-of-type {
        margin-bottom: 0px;
      }
      p:last-of-type {
        margin-top: 25px;
        margin-bottom: 0px;
      }
      width: 470px;
      margin-left: 90px;
    }
  }
`

export const ActivitiesPanel = () => {
  return (
    <MainContainer>
      <PanelContainer>
        <StaticImage
          src="../../images/programs/mlove/activities.png"
          placeholder="none"
          quality={100}
          alt="children from classroom"
        />
        <div className="text-container">
          <h4>Activities</h4>
          <p>
            MLOVE welcomes ten Fellows each year to envision their place
            globally and leverage MLIFE's vast global network, resources, and
            expertise to realize their education, career, and entrepreneurship
            goals to impact
            <br /> the world.
          </p>
          <p>
            The primary activities of this transformative one-year part-time
            program include 15-days of required in-person events that
            <br /> encompass 2-days orientation, 3-weekend community retreats,
            and a 2-day reunion a year after the program.
          </p>
        </div>
      </PanelContainer>
    </MainContainer>
  )
}
